import React, { useState } from "react";
import AddReferenceOrLinkModal from "./AddReferenceOrLinkModal";
function LinkItem() {
  const [open, setOpen] = useState(false);
  const items = [
    {
      id: "we4-4",
      title: "Implement user authentication module",
      type: "Is Blocked By",
    },
    {
      id: "we4-5",
      title: "Optimize database query performance",
      type: "Is Tested By",
    },
    {
      id: "we4-6",
      title: "Design responsive UI for dashboard",
      type: "Is Related To",
    },
    {
      id: "we4-7",
      title: "Set up continuous integration pipeline",
      type: "Is Blocked By",
    },
  ];
  const allLinkItems = (data) => {
    console.log(data);
  };
  return (
    <div className="link-items-box box" id="bx">
      <div className="box-head align-items-center mb_30">
        <h5 className="text-secondary">Link Items </h5>
        <span
          className="text-primary  add_cursor f-12"
          onClick={() => setOpen(true)}
        >
          + Link Item
        </span>
      </div>
      {items.length > 0 &&
        items.map((item, i) => (
          <div key={i} className={`d-flex align-items-center gap-2 mb_12`}>
            <div className="w-20">
              <span className="span-gray-color f-14 ms-1">{item.type}</span>
            </div>
            <div className={`testCase-small-card w-80 mb-0`}>
              <span className="text-primary case-index">{item.id}</span>
              <span className="">{item.title}</span>
            </div>
          </div>
        ))}
      <AddReferenceOrLinkModal
        openReferenceModal={open}
        setOpenReferenceModal={setOpen}
        allReference={allLinkItems}
      />
    </div>
  );
}

export default LinkItem;
