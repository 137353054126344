import React, { useEffect, useState } from "react";
import AddReferenceOrLinkModal from "./AddReferenceOrLinkModal";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { api } from "../../../../customAxios";
import { toast } from "react-toastify";
function AddReference({ testCaseDetail, setTestCaseDetail, setProgress }) {
  const [open, setOpen] = useState(false);

  const updateTestCasaeReference = (data) => {
    setProgress(30);
    var payload = new FormData();
    // var payload = {};
    const referencesIds = data.map((item) => item.id);
    payload.append("reference_ids", JSON.stringify(referencesIds));
    // payload.reference_ids = referencesIds;
    api
      .post(`/test-cases/${testCaseDetail?.id}`, payload)
      .then((res) => {
        setProgress(60);
        toast.success("Test case references updated successfully!.");
        setTestCaseDetail((preData) => ({
          ...preData,
          references: data,
        }));
      })
      .catch((err) => {
        toast.error("Oops! something went wrong.");
      })
      .finally(() => {
        setProgress(100);
      });
  };

  const getReference = (newData) => {
    updateTestCasaeReference(newData);
  };

  const handleDeselectTestCase = (Id) => {
    const updatedArray = testCaseDetail?.references.filter(
      (ref) => ref.id !== Id
    );
    updateTestCasaeReference(updatedArray);
  };

  return (
    <div className="link-items-box box" id="bx">
      <div className="box-head align-items-center mb_30">
        <h5 className="text-secondary">Add Reference</h5>
        <span
          className="text-primary  add_cursor f-12"
          onClick={() => setOpen(true)}
        >
          + Add Reference
        </span>
      </div>
      {testCaseDetail?.references?.length > 0 ? (
        testCaseDetail?.references?.map((reference, i) => (
          <div key={i} className={`d-flex align-items-center gap-2 mb_12`}>
            <div className={`testCase-small-card w-100 mb-0`}>
              <div className="view-test-case w-100">
                <div className="text d-flex justify-content-between">
                  <div className="d-flex">
                    <div className="icon d-flex">
                      <div className="task-done-blue"></div>
                    </div>
                    <p className={`text-capitalize textEllipse case_title m-0`}>
                      {reference.name}
                    </p>
                  </div>
                  <span
                    className="float-end add_cursor span-gray-color"
                    onClick={() => handleDeselectTestCase(reference?.id)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p className="text-muted fs-6 mt-2 mb-2">
          There is not references task
        </p>
      )}

      <AddReferenceOrLinkModal
        openReferenceModal={open}
        setOpenReferenceModal={setOpen}
        isReference={true}
        allReference={getReference}
        references={testCaseDetail?.references}
      />
    </div>
  );
}

export default AddReference;
