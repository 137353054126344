import React, { useEffect, useState } from "react";
import { Form, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AutocompleteLabels from "../../../../componets/comman/AutoCompleteLables";
import CustomAvatar from "../../../../componets/comman/Task/CustomAvatar";
import { api } from "../../../../customAxios";
import { formatName, fullDateFormate } from "../../../../Utils/helpers";
import AddResultModal from "./AddResultModal";
import TestCaseStatus from "./TestCaseStatus";

function ViewTestCaseSidebar(props) {
  const { testCaseDetail, setTestCaseDetail, projectUser, setProgress } = props;

  const [openResult, setOpenResult] = useState(false);
  const [projectLabels, setProjectLabels] = useState([]);
  const { projectId } = useParams();
  const [selectedLabels, setSelectedLabels] = useState(
    testCaseDetail?.testCaseLabels?.map((label) => label.name) || []
  );
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    api.get(`/project/${projectId}/labels`).then((response) => {
      setProjectLabels(response.data);
    });
  }, [testCaseDetail]);
  const handleUpdateTestCase = (type, data) => {
    setProgress(30);
    // const payload = {};
    const payload = new FormData();
    if (type === "status_name") {
      // payload.status_name = data;
      payload.append("status_name", data);
    } else if (type === "labels") {
      payload.append("project_id", parseInt(projectId));
      // payload.project_id = parseInt(projectId);
      payload.append("labels", JSON.stringify(data));
      // payload.labels = JSON.stringify(data);
    }

    api
      .post(`/test-cases/${testCaseDetail?.id}`, payload)
      .then((response) => {
        setProgress(60);
        if (response?.data) {
          toast.success(
            `Test case ${
              type === "status_name" ? "status" : type
            } updated successfully`
          );
          const data = {
            testCaseId: response?.data?.id,
            projectId: projectId,
            updatedTestCase: response?.data,
            executionId: response?.data?.execution_id,
            action: "testCaseUpdate",
            listId: response?.data?.tc_lists_id,
          };
          navigate(location.pathname, { state: { data } });
        }
      })
      .catch((err) => {
        toast.error("Oops! something went wrong.");
      })
      .finally(() => {
        setProgress(100);
      });
  };

  const handleSelectedLabelsChange = (newSelectedLabels) => {
    setSelectedLabels(newSelectedLabels);
    handleUpdateTestCase("labels", newSelectedLabels);
  };

  return (
    <>
      <div className={"routes"}>
        {testCaseDetail?.execution_id !== null && (
          <button
            className="btn btn-default btn-add-result"
            onClick={() => setOpenResult(true)}
          >
            + Add Result
          </button>
        )}
        <div className="zacks d-flex justify-content-between">
          <p className="m-0">Created by:</p>
          <span className="newFeature selected-images-row w_168px textEllipse">
            <div className="d-flex">
              {testCaseDetail?.created_by_image ? (
                <div
                  className="avatar__border userImages size_cover"
                  style={{
                    backgroundImage: `url('${testCaseDetail?.created_by_image}')`,
                  }}
                ></div>
              ) : (
                <CustomAvatar
                  name={testCaseDetail?.created_by_name || ""}
                  backgroundColor={
                    projectUser?.find(
                      (user) => user.name === testCaseDetail?.created_by_name
                    )?.profile_color
                  }
                />
              )}
              <div className="name-time">
                <span className="user-name ms-2 text-capitalize">
                  {formatName(testCaseDetail?.created_by_name)}
                </span>
                <span className="time">
                  Created at : {fullDateFormate(testCaseDetail?.created_at)}
                </span>
              </div>
            </div>
          </span>
        </div>

        <div className="d-flex justify-content-between align-items-center mt-25 task-status">
          <p className="zacks m-0">Status:</p>
          <TestCaseStatus
            isView={true}
            status={testCaseDetail?.status_name}
            testCaseId={testCaseDetail?.id}
            setProgress={setProgress}
            projectId={projectId}
            testCaseDetail={testCaseDetail}
            handleUpdateTestCase={handleUpdateTestCase}
          />
        </div>

        <div
          className="zacks justify-content-between d-flex align-items-center mt-21"
          style={{ position: "relative" }}
        >
          <>
            <p
              className=""
              style={{ marginRight: "10px", position: "absolute", top: "0" }}
            >
              Label:
            </p>
            <div
              className="label-task labels-view_task"
              style={{ width: "160px", marginLeft: "auto" }}
            >
              <AutocompleteLabels
                selectedLabels={selectedLabels}
                onSelectedLabelsChange={handleSelectedLabelsChange}
                projectLabels={projectLabels?.map((label) => label.name)}
                testCaseDetail={testCaseDetail}
                isTestCase={true}
              />
            </div>
          </>
        </div>
      </div>

      {openResult && (
        <AddResultModal
          openAddResultModal={openResult}
          setOpenAddResultModal={setOpenResult}
          projectId={projectId}
          testCaseDetail={testCaseDetail}
          setTestCaseDetail={setTestCaseDetail}
        />
      )}
    </>
  );
}

export default ViewTestCaseSidebar;
